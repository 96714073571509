.toggle-button {
    display: block;
  }
  
  .toggle-button.is-disabled,
  .toggle-button:disabled {
    background-color: #fff;
    border-color: #6b6b6b;
  }
  
  .toggle-button.is-disabled,
  .toggle-button.is-disabled ~ .toggle-button-icon,
  .toggle-button.is-disabled ~ .toggle-button-label-wrapper .toggle-button-label,
  .toggle-button.is-disabled ~ .toggle-button-label,
  .toggle-button:disabled,
  .toggle-button:disabled ~ .toggle-button-icon,
  .toggle-button:disabled ~ .toggle-button-label-wrapper .toggle-button-label,
  .toggle-button:disabled ~ .toggle-button-label {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .toggle-button-switch {
    width: 2.625rem;
    height: 1.5rem;
    position: relative;
    border: 1px solid #6b6b6b;
    margin-right: 0.75rem;
    margin-bottom: 1.5rem;
    padding: 0;
    border-radius: 0.75rem;
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .toggle-button-switch.active {
    background-color: #46a800;
  }
  
  .toggle-button-switch.inactive {
    transition: background-color 0.2s ease;
    background-color: #ededed;
    position: relative;
  }
  
  .toggle-button-switch_witchout_focus:focus {
    outline: none;
  }
  
  [role="switch"][aria-checked="false"] :first-child,
  [role="switch"][aria-checked="true"] :last-child {
    background: #fff;
    border: 1px solid #6b6b6b;
  }
  
  .toggle-button-label {
    font-family: TeleGroteskNext, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0;
    color: #262626;
    text-decoration: none;
    display: inline-flex;
    vertical-align: top;
    cursor: pointer;
  }
  
  .toggle-button-circle {
    transition: background-color 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, background-color 0.2s ease;
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    border-radius: 50%;
    box-sizing: border-box;
    transform: translateX(0);
  }
  
  .toggle-button-circle.active {
    transition: transform 0.2s ease;
    left: auto;
    transform: translateX(72%);
  }
  
  .toggle-button.theme_dark,
  .toggle-button.theme_magenta {
    color: #fff;
    background-color: transparent !important;
  }
  
  .toggle-button.theme_dark .toggle-button-label,
  .toggle-button.theme_magenta .toggle-button-label {
    color: #fff;
  }
  
  .toggle-button.theme_dark .toggle-button.is-disabled,
  .toggle-button.theme_dark .toggle-button:disabled,
  .toggle-button.theme_magenta .toggle-button.is-disabled,
  .toggle-button.theme_magenta .toggle-button:disabled {
    border-color: #6b6b6b;
  }
  
  .toggle-button.theme_dark .toggle-button-switch,
  .toggle-button.theme_dark [role="switch"][aria-checked="false"] :first-child,
  .toggle-button.theme_dark [role="switch"][aria-checked="true"] :last-child,
  .toggle-button.theme_magenta .toggle-button-switch,
  .toggle-button.theme_magenta [role="switch"][aria-checked="false"] :first-child,
  .toggle-button.theme_magenta [role="switch"][aria-checked="true"] :last-child {
    border: 1px solid #a3a3a3;
  }
  