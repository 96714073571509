
.card {
    height: 100%;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0px 8px 32px 0px hsla(0, 0%, 0%, 0.1), 0px 4px 8px 0px hsla(0, 0%, 0%, 0.1);
  
    // :hover{
    //   box-shadow: 0px 16px 64px 0px hsla(0, 0%, 0%, 0.1), 0px 8px 16px 0px hsla(0, 0%, 0%, 0.1);
    // }
  }
  
  .card-title {
    height: 50px;
    font-weight: bold;
    // padding: 0 20px;
  }
  .card-body {
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-button {
    font-size: 12px;
    height: 20px;
    width: 60px;
    padding: 0 3px;
  }