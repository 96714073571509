.stepper {
    display: flex;
    margin: 12px 0;
    .step {
        position: relative;
        display: inline-block;
        flex: 1;
        padding: ($spacer * 1.5) ($spacer *0.5) 0;
        color: $gray-600;
        text-align: center;
        cursor: pointer;
        background: none;
        border: none;
        outline-offset: 3px;
        transition: all 150ms cubic-bezier(.445, .05, .55, .95);

        & > a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &::before {
            position: absolute;
            top: $spacer * -1;
            left: 50%;
            z-index: 1;
            display: block;
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: TelekomIcon;
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
            line-height: 1;
            color: $gray-300;
            text-align: center;
            letter-spacing: 1px;
            content: " ";
            background-color: $white;
            border-radius: 50%;
            transform: translateX(-50%);
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            content: " ";
            background-color: $border-color;
        }

        &:first-child {
            &::after {
                left: 50%;
                width: 50%;
            }
        }

        &:last-child {
            &::after {
                width: 50%;
            }
        }

        &:not(.completed):not(.danger) {
            &::before {
                width: 24px;
                height: 24px;
                background-color: $gray-300;
                border: 2px solid $white;
            }
        }

        &:not(.completed):not(.danger).active,
        &:not(.completed):not(.danger):active {
            &::before {
                background-color: $gray-600;
            }
        }

        &.completed {
            &::before {
                font-size: 1.5rem;
                color: $pink;
                content: "\76";
            }
        }

        &.danger {
            &::before {
                font-size: 1.5rem;
                color: $danger;
                content: "\78";
            }
        }

        &.disabled,
        &:disabled {
            cursor: not-allowed;
            opacity: $btn-disabled-opacity;
        }

        &:focus {
            outline: 5px auto $btn-focus-color;
            outline-offset: 1px;
        }

        &:not(.disabled):not(:disabled):hover {
            color: $black;
            text-decoration: none;
        }

        .step-title {
            font-weight: $font-weight-bold;
        }
    }
}
