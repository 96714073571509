.sim-icon-enabled {
  filter: none;
}

.sim-icon-disabled {
  filter: invert(10%) sepia(10%) saturate(0%) hue-rotate(293deg) brightness(90%) contrast(95%);
}

.basic-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #e20074;
}

.custom-basic-button {
  margin: 0;
  border: 0;
  border-radius: 0;
  width: '25%';
  height: '2.5rem';
}

.icon-button {
  display: block;
  background-color: transparent;
  margin: auto;
  padding: 0.2rem 0.8rem;

  &:hover:enabled {
    color: rgb(226, 0, 116);
    
    ~ span {
      color: rgb(226, 0, 116);
    }
  }
}

.screen-container {
  display: flex;
  flex-direction: column;
}

.spinner-screen {
  position: relative;
  bottom: 50%;
  scale: 1.5;
  width: 2rem;
  height: 2rem;
  margin: auto;
}

.spinner-text {
  position: relative;
  bottom: 48.5%;
  margin: auto;
}

.screen {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.op-50 {
  opacity: 50%;
}