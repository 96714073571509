$grid-breakpoints: (
    xs: 0,
    sm: 320px,
    md: 640px,
    lg: 1024px,
    xl: 1344px
) !default;

$container-max-widths: (
    lg: 976px,
    xl: 1296px
) !default;

@import "../telekom-scss/telekom-styles";

@import "autocomplete";
@import "brandbar";
@import "close";
@import "custom-forms";
@import "datepicker";
@import "footer";
@import "forms";
@import "hero";
@import "icons";
@import "input-group";
@import "layers";
@import "navbar";
@import "navbar2";
@import "plain-link";
@import "preloader";
@import "reboot";
@import "spinner";
@import "stepper";
@import "table";
@import "tabs";
@import "text-button";
@import "utilities/cursor";
@import "utilities/bu-spacing";
@import "utilities/overflow";
@import "utilities/sizing";
