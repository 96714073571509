.rti--container * {
  align-items: stretch;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rti--container {
  --rti-bg: #fff;
  --rti-border: #ccc;
  --rti-main: #3182ce;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem;
  --rti-tag: #edf2f7;
  --rti-tag-remove: #e53e3e;
  --rti-tag-padding: 0.15rem 0.25rem;

  /* Container Styles */
  align-items: center;
  background: var(--rti-bg);
  border: 1px solid var(--rti-border);
  border-radius: var(--rti-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--rti-s);
  line-height: 1.4;
  padding: var(--rti-s);
}
.rti--container.disabled {
  background-color: #DCDCDC;
}

.rti--container:focus-within {
  border-color: var(--rti-main);
  box-shadow: var(--rti-main) 0px 0px 0px 1px;
}

.rti--input {
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 50%;
  flex: 1;
  min-width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.rti--input[disabled] {
  color: #6B6B6B;
  background-color: #DCDCDC;
}

.rti--tag {
  align-items: center;
  background: var(--rti-tag);
  border-radius: var(--rti-radius);
  display: inline-flex;
  justify-content: center;
  padding: var(--rti-tag-padding);
}

.rti--tag button {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  padding: 0 var(--rti-s);
}

.rti--tag button:hover {
  color: var(--rti-tag-remove);
}

.wrapper-container {
  position: relative;
}


.autosuggest-container {
  background-color: white;
  border: 1px solid #ccc;
  top: -5px;
  padding-top: 4px;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: calc(100% - 3px);
  left: calc(50%);
  transform: translateX(-50%);
  overflow-y: scroll;
  max-height: 150px;
}

.autosuggest-item {
  user-select: none;
  padding: 1px 10px 1px 10px;
  font-size: 20px;
}

.autosuggest-item:hover {
  cursor: pointer;
  color: #191919;
  text-decoration: none;
  background-color: #EDEDED;
}