// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable
$white:    #fff !default;
$gray-100: #EDEDED !default; //Grau 4/237
$gray-200: #DCDCDC !default; //Grau 220
$gray-300: #B2B2B2 !default; //Grau 178
$gray-400: #6B6B6B !default; //Grau 107
$gray-500: #262626 !default; //Grau 38
$gray-600: #757575 !default; // unused
$gray-700: #495057 !default; // unused
$gray-800: #343a40 !default; // unused
$gray-900: #212529 !default; // unused
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #00739F; //Telekom Dunkelblau
$indigo:  #6610f2 !default; //unused
$purple:  #6f42c1 !default; //unused
$pink:    #e20074; //Telekom Magenta
$red:     #d90000; 
$orange:  #fd7e14 !default; //unused
$yellow:  #ffff00; //Telekom Gelb
$green:   #6bb324; 
$teal:    #20c997 !default; //unused
$cyan:    #31C3F7; //Telekom Hellblau
$functional-blue: #00A0DE !default; //Telekom Funktionsblau
$functional-yellow: #FECB00 !default; //Telekom Funktionsgelb
$functional-green: $green !default; //Telekom Funktionsgrün
$functional-red: $red !default; //Telekom Funktionsrot

$colors: () !default;
$colors: map-merge((
  "blue":       $blue,
  "magenta":       $pink,
  "red":        $red,
  "yellow":     $yellow,
  "green":      $green,
  "light-blue":       $cyan,
  "functional-blue": $functional-blue,
  "functional-yellow": $functional-yellow,
  "functional-green": $functional-green,
  "functional-red": $functional-red,
  "white":      $white,
  "gray":       $gray-300,
  "gray-dark":  $gray-500
), $colors);

$primary:       $pink;
$secondary:     $gray-100;
$success:       $functional-green;
$info:          $functional-blue;
$warning:       $functional-yellow;
$danger:        $functional-red;
$light:         $white;
$dark:          $gray-500;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-500 !default;
$yiq-text-light:            $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: .75rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 2),
  5: ($spacer * 3)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
), $sizes);

// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-500 !default;

// Links
//
// Style anchor elements.

$link-color:                $blue;
$link-decoration:           none !default;
$link-hover-color:          #00729d;
$link-hover-decoration:     underline !default;

$close-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,0.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='#262626'/%3E%3C/svg%3E"), "#", "%23") !default;
$arrow-icon: url("data:image/svg+xml,%3Csvg width='12px' height='7px' viewBox='0 0 12 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-593.000000, -1558.000000%29' fill='%234B4B4B'%3E%3Cpath d='M593.142578,1559.1543 C593.048828,1559.03711 593.001953,1558.91113 593.001953,1558.77637 C593.001953,1558.6416 593.048828,1558.52734 593.142578,1558.43359 C593.236329,1558.33984 593.353515,1558.29297 593.494141,1558.29297 C593.634766,1558.29297 593.751953,1558.33984 593.845703,1558.43359 L598.996094,1563.58398 L604.146484,1558.43359 C604.240235,1558.33984 604.357421,1558.29297 604.498047,1558.29297 C604.638673,1558.29297 604.755859,1558.34277 604.849609,1558.44238 C604.94336,1558.54199 604.990234,1558.65918 604.990234,1558.79395 C604.990234,1558.92871 604.94336,1559.04883 604.849609,1559.1543 L598.996094,1565.00781 L593.142578,1559.1543 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$arrow-icon-light: url("data:image/svg+xml,%3Csvg width='12px' height='7px' viewBox='0 0 12 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-593.000000, -1558.000000%29' fill='%23FFFFFF'%3E%3Cpath d='M593.142578,1559.1543 C593.048828,1559.03711 593.001953,1558.91113 593.001953,1558.77637 C593.001953,1558.6416 593.048828,1558.52734 593.142578,1558.43359 C593.236329,1558.33984 593.353515,1558.29297 593.494141,1558.29297 C593.634766,1558.29297 593.751953,1558.33984 593.845703,1558.43359 L598.996094,1563.58398 L604.146484,1558.43359 C604.240235,1558.33984 604.357421,1558.29297 604.498047,1558.29297 C604.638673,1558.29297 604.755859,1558.34277 604.849609,1558.44238 C604.94336,1558.54199 604.990234,1558.65918 604.990234,1558.79395 C604.990234,1558.92871 604.94336,1559.04883 604.849609,1559.1543 L598.996094,1565.00781 L593.142578,1559.1543 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$telekom-logo: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='76px' height='37.2px' viewBox='0 0 76 37.2' style='enable-background:new 0 0 76 37.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E20074;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M0.2,24.7v-7.5h3.6h3.9v7.5H0.2z'/%3E%3Cpath class='st0' d='M23.2,24.7v-7.5h3.7h3.8v7.5H23.2z'/%3E%3Cpath class='st0' d='M45.7,24.7v-7.5h3.5h4v7.5H45.7z'/%3E%3Cpath class='st0' d='M68.3,24.7v-7.5h4h3.5v7.5H68.3z'/%3E%3Cpath class='st0' d='M30.3,0.1H0.6L0.2,13.2l2,0.3c0.4-3.9,1.4-6.7,3-8.6c1.7-2,4.1-3,7.2-3.1v27.4c0,2.4-0.3,3.9-1,4.7 c-0.6,0.6-1.6,1-3,1.1c-0.4,0-1.1,0.1-2.1,0.1v2.1h18.3v-2.1c-1,0-1.7,0-2.1-0.1c-1.4-0.1-2.4-0.5-3-1.1c-0.7-0.7-1-2.3-1-4.7V1.9 c3.1,0.1,5.5,1.1,7.2,3.1c1.7,1.9,2.7,4.7,3,8.6l2-0.3L30.3,0.1z'/%3E%3C/g%3E%3C/svg%3E");

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.2 !default;
$line-height-sm:              1.2 !default;

$border-width:                1px !default;
$border-color:                $gray-300;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         $pink !default;

$caret-width:                 .3em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "TeleGroteskNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

// stylelint-enable value-keyword-case

$font-size-base:              1.125rem; // Assumes the browser default, typically `18px`
$font-size-lg:                1.3125rem !default;
$font-size-sm:                .9375rem !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.225 !default;

$h1-font-size:                3rem; //48px
$h2-font-size:                2.25rem; //36px
$h3-font-size:                1.5rem; //24px
$h4-font-size:                1.3125rem; //21px
$h5-font-size:                $font-size-base; //18px
$h6-font-size:                .9375rem; //15px

$headings-margin-bottom:      $spacer;
$headings-font-family:        inherit !default;
$headings-font-weight:        400 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$display1-size:               5rem !default;
$display2-size:               4.5rem !default;
$display3-size:               3.75rem !default;
$display4-size:               3.375rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              1.3125rem !default;
$lead-font-weight:            400 !default;

$small-font-size:             84% !default;

$text-muted:                  $gray-400 !default;

$blockquote-small-color:      $gray-400 !default;
$blockquote-font-size:        1.3125rem;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .25em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         1.75rem;

$mark-bg:                     $yellow !default;

$hr-margin-y:                 1.4rem;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-bg:                    transparent !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-bg:              $gray-100 !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          $border-width !default;
$table-border-color:          $gray-300;

$table-head-bg:               $gray-400;
$table-head-color:            $gray-100;

$table-dark-bg:               $gray-100;
$table-dark-accent-bg:        $gray-200;
$table-dark-hover-bg:         $gray-200;
$table-dark-border-color:     $gray-300;
$table-dark-color:            $gray-500;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         $spacer * 2;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .1rem !default;
$input-btn-focus-color:       rgba($cyan, 1) !default;
$input-btn-focus-box-shadow:  none !default;

$input-btn-padding-y-sm:      .438rem;
$input-btn-padding-x-sm:      $spacer * 2;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .66rem;
$input-btn-padding-x-lg:      $spacer * 2;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .5 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-500 !default;

$btn-block-spacing-y:         .5rem !default;

$btn-focus-color: $cyan !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              all 150ms cubic-bezier(.445, .05, .55, .95);

// Forms

$label-margin-bottom:                   .3rem !default;
$label-color:                           $gray-400 !default;
$label-font-size:                       $font-size-sm !default;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $spacer !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $spacer !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $spacer !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $white;
$input-readonly-bg:                     $gray-200 !default;

$input-color:                           $gray-500 !default;
$input-border-color:                    $gray-300 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-lg:                $border-radius-lg !default;
$input-border-radius-sm:                $border-radius-sm !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $functional-blue;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-400 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:                 ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:                  .25rem !default;

$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .3rem !default;
$form-check-input-margin-x:             .25rem !default;

$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-group-margin-bottom:              1rem !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-100 !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-control-gutter:                 2.2rem !default;
$custom-control-spacer-x:               1rem !default;

$custom-control-indicator-size:         1.5rem !default;
$custom-control-indicator-bg:           $gray-100 !default;
$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-control-indicator-disabled-bg:          $gray-100 !default;
$custom-control-label-disabled-color:           $gray-500 !default;

$custom-control-indicator-checked-color:        $pink;
$custom-control-indicator-checked-bg:           $gray-100;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("secondary"), .5) !default;
$custom-control-indicator-checked-box-shadow:   none !default;

$custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color:         $gray-200;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow:    none !default;

$custom-checkbox-indicator-border-radius:       $border-radius !default;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Forms' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Forms_Agent-States' transform='translate%28-870.000000, -824.000000%29' fill='%236BB324'%3E%3Cg id='Checkboxes-onRelease' transform='translate%28865.000000, 816.000000%29'%3E%3Cg id='Check' transform='translate%280.000000, 1.000000%29'%3E%3Cpath d='M5.40625,13.7070312 C5.19531145,13.5078115 5.08984375,13.2734388 5.08984375,13.0039062 C5.08984375,12.7343737 5.19238179,12.500001 5.39746094,12.3007812 C5.60254009,12.1015615 5.83691274,12.0019531 6.10058594,12.0019531 C6.36425913,12.0019531 6.59570213,12.1015615 6.79492188,12.3007812 L9.80078125,15.0957031 L17.2011719,7.6953125 C17.4003916,7.49609275 17.6318346,7.39648438 17.8955078,7.39648438 C18.159181,7.39648438 18.3935537,7.49609275 18.5986328,7.6953125 C18.803712,7.89453225 18.90625,8.1289049 18.90625,8.3984375 C18.90625,8.6679701 18.8007823,8.90234275 18.5898438,9.1015625 L9.80078125,17.8027344 L5.40625,13.7070312 Z' id='Checkmark'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg:          $gray-200;
$custom-checkbox-indicator-indeterminate-color:       $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:  none !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 12 12'%3E%3Ccircle r='6' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y:           .375rem !default;
$custom-select-padding-x:           .75rem !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-btn-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-400 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $gray-200 !default;
$custom-select-bg-size:             13px 18px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-500 !default;
$custom-select-indicator:           url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='13px' height='18px' viewBox='0 0 13 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Forms' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Forms_01' transform='translate%28-1049.000000, -444.000000%29' fill='%23262626'%3E%3Cg id='select-closed' transform='translate%28760.000000, 416.000000%29'%3E%3Cpath d='M289.399721,40.1542969 C289.305971,40.0371088 289.259096,39.9111335 289.259096,39.7763672 C289.259096,39.6416009 289.305971,39.5273442 289.399721,39.4335938 C289.493471,39.3398433 289.610658,39.2929688 289.751284,39.2929688 C289.891909,39.2929688 290.009096,39.3398433 290.102846,39.4335938 L295.253237,44.5839844 L300.403627,39.4335938 C300.497378,39.3398433 300.614564,39.2929688 300.75519,39.2929688 C300.895815,39.2929688 301.013002,39.3427729 301.106752,39.4423828 C301.200503,39.5419927 301.247377,39.659179 301.247377,39.7939453 C301.247377,39.9287116 301.200503,40.0488276 301.106752,40.1542969 L295.253237,46.0078125 L289.399721,40.1542969 Z M289.399721,34.1464844 L295.253237,28.2929688 L301.106752,34.1464844 C301.200503,34.2519537 301.247377,34.3720696 301.247377,34.5068359 C301.247377,34.6416022 301.200503,34.7587886 301.106752,34.8583984 C301.013002,34.9580083 300.895815,35.0078125 300.75519,35.0078125 C300.614564,35.0078125 300.497378,34.960938 300.403627,34.8671875 L295.253237,29.7167969 L290.102846,34.8671875 C290.009096,34.960938 289.891909,35.0078125 289.751284,35.0078125 C289.610658,35.0078125 289.493471,34.960938 289.399721,34.8671875 C289.305971,34.773437 289.259096,34.6591804 289.259096,34.5244141 C289.259096,34.3896478 289.305971,34.2636725 289.399721,34.1464844 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$custom-select-border-width:        $input-btn-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-box-shadow:    inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm:        75% !default;
$custom-select-height-sm:           $input-height-sm !default;

$custom-select-font-size-lg:        125% !default;
$custom-select-height-lg:           $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         2px;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $gray-300 !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:            1.5rem !default;
$custom-range-thumb-height:           $custom-range-thumb-width !default;
$custom-range-thumb-bg:               $gray-100 !default;
$custom-range-thumb-border:           1px solid $gray-300;
$custom-range-thumb-border-radius:    50%;
$custom-range-thumb-box-shadow:       0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;
$custom-range-thumb-active-bg:        $gray-100;

$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-btn-focus-box-shadow !default;

$custom-file-padding-y:             $input-btn-padding-y !default;
$custom-file-padding-x:             $input-btn-padding-x !default;
$custom-file-line-height:           $input-btn-line-height !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-btn-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
    en: "Browse"
) !default;


// Form validation
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;
$form-feedback-valid-color:         theme-color("success") !default;
$form-feedback-invalid-color:       theme-color("danger") !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-divider-bg:               $gray-200 !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $gray-500 !default;
$dropdown-link-hover-color:         darken($gray-500, 5%) !default;
$dropdown-link-hover-bg:            $gray-100 !default;

$dropdown-link-active-color:        $white !default;
$dropdown-link-active-bg:           $pink !default;

$dropdown-link-disabled-color:      $gray-400 !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $gray-400 !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Navs

$nav-link-padding-y:                .3rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-400 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $pink !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $pink;
$nav-pills-link-active-bg:          transparent;

$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              ($spacer * 0.5) !default;

// Navbar

$navbar-padding-y:                  ($spacer * 0.5) !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .75rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * 0.5 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                $gray-500;
$navbar-light-hover-color:          $gray-500;
$navbar-light-active-color:         $pink;
$navbar-light-disabled-color:       $gray-400;
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $gray-500 !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           $gray-300 !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $gray-500 !default;
$pagination-hover-bg:               $gray-200 !default;
$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gray-400 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;


// Jumbotron

$jumbotron-padding:                 2rem !default;
$jumbotron-bg:                      $gray-100 !default;


// Cards

$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.5rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 $gray-300 !default;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       $gray-100 !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 ($grid-gutter-width * 0.5) !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;


// Tooltips

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $gray-500 !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 .55rem !default;
$tooltip-padding-x:                 .75rem !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              13px;
$tooltip-arrow-color:               $tooltip-bg !default;


// Popovers

$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              $gray-300 !default;
$popover-border-radius:             $border-radius !default;
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg:                 $gray-100 !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              13px !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         $popover-border-color !default;

// Badges

$badge-font-size:                   75% !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-padding-y:                   .25em !default;
$badge-padding-x:                   .4em !default;
$badge-border-radius:               $border-radius !default;

$badge-pill-padding-x:              .5em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               1rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-border-color:         $gray-300 !default;
$modal-footer-border-color:         $gray-200 !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding:              1rem !default;

$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;

$modal-transition:                  transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.5rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                $border-width !default;

$alert-bg-level:                    0 !default;
$alert-border-level:                0 !default;
$alert-color-level:                 0 !default;


// Progress bars

$progress-height:                   1rem !default;
$progress-font-size:                ($font-size-base * .75) !default;
$progress-bg:                       $gray-200 !default;
$progress-border-radius:            0 !default;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   theme-color("primary") !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;

// List group

$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:         1.5rem !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $white !default;
$list-group-active-bg:              $pink !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-400 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-500 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;


// Image thumbnails

$thumbnail-padding:                 0;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            0;
$thumbnail-border-color:            transparent;
$thumbnail-border-radius:           0;
$thumbnail-box-shadow:              0;


// Figures

$figure-caption-font-size:          .9375rem;
$figure-caption-color:              $gray-400 !default;

// Breadcrumbs

$breadcrumb-padding-y:              .35rem !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-400;
$breadcrumb-active-color:           $gray-500;
$breadcrumb-divider:                "\2002" !default;

$breadcrumb-divider-icon:           url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='6px' height='10px' viewBox='0 0 6 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Alerts' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Desktop-II' transform='translate%28-132.000000, -852.000000%29' fill='%23262626' fill-rule='nonzero'%3E%3Cg id='arrow' transform='translate%28132.000000, 852.000000%29'%3E%3Cpath d='M0.369565217,9.80434783 C0.282608696,9.80434783 0.195652174,9.7826087 0.130434783,9.7173913 C7.63278329e-17,9.58695652 7.63278329e-17,9.39130435 0.130434783,9.26086957 L4.47826087,4.91304348 L0.152173913,0.586956522 C0.0217391304,0.456521739 0.0217391304,0.260869565 0.152173913,0.130434783 C0.282608696,-3.26128013e-16 0.47826087,-3.05311332e-16 0.608695652,0.130434783 L5.17391304,4.69565217 C5.30434783,4.82608696 5.30434783,5.02173913 5.17391304,5.15217391 L0.608695652,9.7173913 C0.543478261,9.7826087 0.456521739,9.80434783 0.369565217,9.80434783 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$breadcrumb-border-radius:          0;

// Carousel

$carousel-control-color:            $white !default;
$carousel-control-width:            15% !default;
$carousel-control-opacity:          1 !default;

$carousel-indicator-width:          12px !default;
$carousel-indicator-height:         12px !default;
$carousel-indicator-spacer:         10px !default;
$carousel-indicator-active-bg:      $pink !default;

$carousel-caption-width:            70% !default;
$carousel-caption-color:            $white !default;

$carousel-control-icon-width:       32px !default;
$carousel-control-icon-height:      19px !default;

$carousel-control-prev-icon-bg:     $arrow-icon-light;
$carousel-control-next-icon-bg:     $arrow-icon-light;

$carousel-transition:               transform .6s ease !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Close

$close-font-size:                   $font-size-base * 1.5 !default;
$close-font-weight:                 $font-weight-bold !default;
$close-color:                       $black !default;
$close-text-shadow:                 0 1px 0 $white !default;

// Code

$code-font-size:                    87.5% !default;
$code-color:                        $pink !default;

$kbd-padding-y:                     .2rem !default;
$kbd-padding-x:                     .4rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $white !default;
$kbd-bg:                            $gray-500 !default;

$pre-color:                         $gray-500 !default;
$pre-scrollable-max-height:         340px !default;


// Printing
$print-page-size:                   a3 !default;
$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;
