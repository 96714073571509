.text-button {
    display: inline;
    padding: 0;
    margin: 0;
    color: inherit;
    text-decoration: $link-decoration;
    cursor: pointer;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    border: none;
    outline-offset: 5px;
    transition: all 150ms cubic-bezier(.445, .05, .55, .95);
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

    @include hover {
        color: inherit;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 5px auto $btn-focus-color;
        outline-offset: 3px;
    }
    &:disabled {
        cursor: not-allowed;
        filter: contrast(0.3);
    }
}

.text-button-link {
    @extend .text-button;

    color: $link-color;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}
