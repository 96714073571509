@use "sass:math";

.tab {
    display: inline-block;
    padding: $spacer math.div($spacer, 3);
    margin: 0 (($spacer * 1.5) - math.div($spacer, 3));
    margin-bottom: -1px;
    // color: $gray-600;
    color: $black;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: math.div($spacer, 3) solid transparent;
    outline-offset: 3px;
    transition: all 150ms cubic-bezier(.445, .05, .55, .95);

    & > a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &:active,
    &.active {
        color: $pink;
        border-bottom: math.div($spacer, 3) solid $pink;

        &:hover {
          color: $pink;
          border-bottom: math.div($spacer, 3) solid $pink;
        }
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:focus {
        outline: 5px auto $btn-focus-color;
        outline-offset: 1px;
    }

    &:hover {
        color: $black;
        text-decoration: none;
        border-bottom: math.div($spacer, 3) solid $pink;
    }
}

.tab-sm {
    font-size: .5rem;
}

.tab-lg {
    font-size: 1.5rem;
}

// Make the div behave like a button
.tabs {
    position: relative;
    display: inline-flex;
    padding: 0 $spacer * 1.5;
    vertical-align: middle; // match .tab alignment given font-size hack above
    border-bottom: 1px solid $border-color; // match .tab alignment given font-size hack above

    &:not(.tabs-vertical) {
        align-items: stretch;
    }
}

.tabs-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .tabs-block {
        margin-top: .5rem;
    }
}

.tabs-same-size {
    display: flex;

    .tab {
        flex: 1;
        text-align: center;
    }
}

.tabs-vertical {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    // margin: 0 $spacer * 1.5;
    margin: 0;
    border-bottom: none;
    border-left: 1px solid $border-color;

    .tab {
        width: 100%;

        /* padding-right: ($spacer * 1.5);
        padding-left: ($spacer * 1.5);
        margin: ($spacer / 2) 1px ($spacer / 2) -1px; */

        padding: math.div($spacer, 3) $spacer;
        margin: (($spacer * 1.5) - math.div($spacer, 3)) 1px;
        margin-left: -1px;
        text-align: left;

        border: none;
        border-left: math.div($spacer, 3) solid transparent;

        &:active,
        &.active {
            border: none;
            border-left: math.div($spacer, 3) solid $pink;
        }

        &:hover {
            border: none;
            border-left: math.div($spacer, 3) solid $gray-100;
        }
    }

    &.tabs-vertical-right {
        // align-items: flex-end;
        border: none;
        border-right: 1px solid $border-color;

        .tab {
            margin-right: -1px;
            margin-left: 1px;
            border: none;
            border-right: math.div($spacer, 3) solid transparent;

            &:active,
            &.active {
                border: none;
                border-right: math.div($spacer, 3) solid $pink;
            }

            &:hover {
                border: none;
                border-right: math.div($spacer, 3) solid $gray-100;
            }
        }
    }
}

.tabs-borderles {
    &:not(.tabs-vertical) {
        border-bottom: 1px solid transparent;
    }

    &.tabs-vertical {
        border-left: 1px solid transparent;
    }

    &.tabs-vertical-right {
        border-right: 1px solid transparent;
    }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.tabs-sm > .tab { @extend .tab-sm; }
.tabs-lg > .tab { @extend .tab-lg; }


.modal-header .tabs {
    padding: 0;
    margin: -1rem;
    border-bottom: none;
}
