.react-calendar-timeline .rct-sidebar-header {
  background: #f0f0f0;
}

.react-calendar-timeline .rct-header-container {
  border-top: 1px solid #bbb;
}

.react-calendar-timeline .rct-header-root {
  background: #e20074;
  border-bottom: none;
}

.react-calendar-timeline .rct-outer {
  margin-left: 1px;
}

.react-calendar-timeline .rct-dateHeader {
  border-left-width: 1px; 
  border-bottom: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-width: 1px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 4px 0 12px;
}

.rct-sidebar-row .highlight,
.react-calendar-timeline .rct-horizontal-lines .highlight {
  background-color: #a8a8a8;
}

.rct-scroll {
  z-index: 1;
}

.rct-item-handler-resize-left,
.rct-item-handler-resize-right{
  cursor: e-resize !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  padding: 24px 12px;
  z-index: 1040;
}


.rct-item-content {
  color: white;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 120%;
  display: block !important;
}