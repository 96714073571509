//
// Icons
// ----------------------------------------

@include telegrotesknext-font-face("TelekomIcon", "teleicon-outline", 400, normal);

@include telegrotesknext-font-face("TelekomIcon", "teleicon-solid", 700, normal);

.icon {
    position: relative;
    bottom: 1px;
    display: inline-block;
    font-family: TelekomIcon; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none !important; /* stylelint-disable-line declaration-no-important */
    vertical-align: middle;
    -webkit-font-smoothing: antialiased; // reduce font weight
    -moz-osx-font-smoothing: grayscale; // reduce font weight
}

.icon-solid {
    font-weight: 700;
}

.icon-large {
    font-size: 36px;
}

.icon-small {
    font-size: 12px;
}

.icon-warning::before                { content: "\21"; }
.icon-navigation-up::before          { content: "\22"; }
.icon-navigation-down::before        { content: "\24"; }
.icon-navigation-left::before        { content: "\25"; }
.icon-export::before                 { content: "\26"; }
.icon-import::before                 { content: "\28"; }
.icon-scroll-left::before            { content: "\29"; }
.icon-favorites::before              { content: "\2a"; }
.icon-add::before                    { content: "\2b"; }
.icon-decrease::before               { content: "\2d"; }
.icon-record::before                 { content: "\3b"; }
.icon-scroll-right::before           { content: "\2f"; }
.icon-tile-view::before              { content: "\30"; }
.icon-skip-to-start::before          { content: "\31"; }
.icon-previous::before               { content: "\32"; }
.icon-rewind::before                 { content: "\33"; }
.icon-play::before                   { content: "\34"; }
.icon-pause::before                  { content: "\35"; }
.icon-stop::before                   { content: "\36"; }
.icon-fast-forward::before           { content: "\37"; }
.icon-next::before                   { content: "\38"; }
.icon-skip-to-end::before            { content: "\39"; }
.icon-more::before                   { content: "\3a"; }
.icon-help_2::before                 { content: "\3c"; }
.icon-list-view::before              { content: "\3d"; }
.icon-information_2::before          { content: "\3e"; }
.icon-help::before                   { content: "\3f"; }
.icon-calendar::before               { content: "\41"; }
.icon-shopping-cart::before          { content: "\42"; }
.icon-copy::before                   { content: "\43"; }
.icon-move-to-trash::before          { content: "\44"; }
.icon-tablet-pc::before              { content: "\45"; }
.icon-folder::before                 { content: "\46"; }
.icon-filter::before                 { content: "\47"; }
.icon-login::before                  { content: "\48"; }
.icon-comment::before                { content: "\49"; }
.icon-start-call::before             { content: "\4a"; }
.icon-speaker-off::before            { content: "\4b"; }
.icon-lock::before                   { content: "\4c"; }
.icon-microphone-socket::before      { content: "\4d"; }
.icon-ringtone-off::before           { content: "\4e"; }
.icon-clock::before                  { content: "\4f"; }
.icon-add-to-watchlist::before       { content: "\50"; }
.icon-computer::before               { content: "\51"; }
.icon-iptv::before                   { content: "\52"; }
.icon-settings::before               { content: "\53"; }
.icon-digits::before                 { content: "\54"; }
.icon-my-profile::before             { content: "\55"; }
.icon-confirm::before                { content: "\56"; }
.icon-smartphone::before             { content: "\57"; }
.icon-cancel::before                 { content: "\58"; }
.icon-cloud::before                  { content: "\59"; }
.icon-zoom-out::before               { content: "\5a"; }
.icon-attachment::before             { content: "\61"; }
.icon-bookmark::before               { content: "\62"; }
.icon-context-menu::before           { content: "\63"; }
.icon-download::before               { content: "\64"; }
.icon-edit::before                   { content: "\65"; }
.icon-file::before                   { content: "\66"; }
.icon-reload::before                 { content: "\67"; }
.icon-home::before                   { content: "\68"; }
.icon-information::before            { content: "\69"; }
.icon-end-call::before               { content: "\6a"; }
.icon-speaker-on::before             { content: "\6b"; }
.icon-link::before                   { content: "\6c"; }
.icon-message::before                { content: "\6d"; }
.icon-ringtone-on::before            { content: "\6e"; }
.icon-loading-indicator::before      { content: "\6f"; }
.icon-print::before                  { content: "\70"; }
.icon-search::before                 { content: "\71"; }
.icon-reply::before                  { content: "\72"; }
.icon-synchronize::before            { content: "\73"; }
.icon-telekom-1T1::before            { content: "\74"; }
.icon-upload::before                 { content: "\75"; }
.icon-success::before                { content: "\76"; }
.icon-forward::before                { content: "\77"; }
.icon-error::before                  { content: "\78"; }
.icon-share::before                  { content: "\79"; }
.icon-zoom-in::before                { content: "\7a"; }
.icon-navigation-right::before       { content: "\a7"; }
.icon-maximize::before               { content: "\dc"; }
.icon-cover-view::before             { content: "\df"; }
.icon-warning_2::before              { content: "\e4"; }
.icon-minimize::before               { content: "\fc"; }
