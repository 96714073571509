// TeleGroteskNext mixins
$telegrotesk-font-path: "../fonts" !default;

@mixin telegrotesknext-font-face($font-family, $filename, $weight: normal, $style: normal) {
    $filepath: "../../fonts/#{$telegrotesk-font-path}/#{$filename}";

    @font-face {
        font-family: $font-family;
        font-style: $style;
        font-weight: $weight;
        src: url("#{$filepath}.eot");
        src: url("#{$filepath}.eot?#iefix") format("embedded-opentype"), url("#{$filepath}.woff2") format("woff2"), url("#{$filepath}.woff") format("woff"), url("#{$filepath}.ttf") format("truetype"), url("#{$filepath}.svg##{$filename}") format("svg");
    }
}