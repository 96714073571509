.footer {
    font-size: $small-font-size;
    line-height: $small-font-size;
    color: $gray-300 !important; /* stylelint-disable-line declaration-no-important */
    background-color: $gray-500;

    &.footer-minimal {
        color: $gray-400 !important; /* stylelint-disable-line declaration-no-important */
        background-color: $white;
    }

    .footer-navigation {
        display: flex;
        padding: $spacer * 2 0 $spacer * 4;

        .footer-navigation-column {
            @extend .col-3;
            flex: 1;
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .footer-navigation-column-item {
            display: flex;
            align-items: center;
            min-height: $spacer * 2;
            margin-bottom: $spacer;
            color: $gray-300;

            &.footer-navigation-column-title {
                margin: $spacer * 2 0 $spacer * 2.5;
                font-size: $font-size-base;
                font-weight: $font-weight-bold;
                line-height: $font-size-base;
                color: $white;
            }
        }

        .footer-navigation-link {
            display: flex;
            align-items: center;
            padding: 0;
            color: inherit;
            text-decoration: none;
            cursor: pointer;
            background: none;
            border: none;
            outline-offset: 4px;
            transition: all 150ms cubic-bezier(.445, .05, .55, .95);

            &:hover,
            &.hover {
                text-decoration: underline;
            }

            &:focus,
            &.focus {
                outline: 5px auto $btn-focus-color;
                outline-offset: 2px;
            }
        }

        .footer-navigation-icon {
            width: auto;
            height: $spacer * 2;
            margin-right: $spacer * .5;
            color: $white;
        }
    }

    .footer-additional {
        display: flex;
        padding: $spacer * 4 0 0;

        .footer-additional-link {
            color: $cyan;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .footer-divider {
        min-height: 1px;
        border-bottom: $border-color;
    }

    .footer-brandbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }

    .footer-copy {
        display: flex;
        justify-content: space-between;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }

    .footer-claim {
        font-size: $small-font-size;
        font-weight: $font-weight-bold;
        line-height: $small-font-size;
        color: $white;
        text-transform: uppercase;
    }

    .footer-copy-navigation-container {
        display: flex;
    }

    .footer-copy-navigation {
        display: flex;
        padding: 0;
        margin: 0 ($spacer * 3) 0 0;
        list-style: none;

        &:last-child {
            margin-right: 0;
        }
    }

    .footer-copy-navigation-item {
        margin-right: $spacer;
        line-height: $small-font-size;

        &:last-child {
            margin-right: 0;
        }
    }

    .footer-copy-navigation-link {
        padding: 0;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        background: none;
        border: none;
        outline-offset: 4px;
        transition: all 150ms cubic-bezier(.445, .05, .55, .95);

        &:active,
        &.active,
        &:hover,
        &.hover {
            text-decoration: underline;
        }

        &:focus,
        &.focus {
            outline: 5px auto $btn-focus-color;
            outline-offset: 2px;
        }
    }
}

.footer-content {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  justify-content: center;
  width: 100%;
  padding: 0 50px;

  .footer-logo {
    margin: 0 50px;
  }

  .footer-copy {
    margin: 0 100px;
  }

  .footer-nav {
    margin-left: 0 100px;
  }

  .footer-nav-btn {
    background: none;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 1rem;


    &:hover {

    }
  }
}