.reservations-timeline-sidebar-title-container {
    display: flex;

    &.header {
        font-weight: 500;
    }
}

.reservations-timeline-sidebar-title-column.selection {
    width: 18px;
}

.reservations-timeline-sidebar-title-column:not(.selection) {
    flex: 1 0 0px;
    min-width: 1px;

    padding: 0 0.75rem;

    &:first-child {
        flex-grow: 2;
    }
}