.layers-container {
    position: relative;
    display: inline-block;
}

.singlelayer {
    position: absolute;
    padding: $spacer;
    overflow: hidden;
    color: $white;
    background-color: $pink;

    &.transparent {
        background-color: rgba(226, 0, 116, .7);
    }

    &:not(.right):not(.top):not(.top-right):not(.top-left):not(.bottom):not(.bottom-right):not(.bottom-left):not(.left) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $spacer;
    }

    &.top {
        top: 0;
        width: 100%;
        max-height: 50%;
    }

    &.top-right {
        top: $spacer;
        right: 0;
        max-width: 60%;
    }

    &.top-left {
        top: $spacer;
        left: 0;
        max-width: 60%;
    }

    &.right {
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
    }

    &.bottom {
        bottom: 0;
        width: 100%;
        max-height: 50%;
    }

    &.bottom-right {
        right: 0;
        bottom: $spacer;
        max-width: 60%;
    }

    &.bottom-left {
        bottom: $spacer;
        left: 0;
        max-width: 60%;
    }

    &.left {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
    }
}

.multilayer {
    position: absolute;
    top: $spacer;
    left: $spacer;
    z-index: 1;
    max-width: 60%;
    padding: $spacer $spacer $spacer * 3;
    color: $white;
    background-color: $pink;

    &::before,
    &::after {
        position: absolute;
        z-index: -1;
        display: block;
        content: " ";
        background-color: $pink;
        opacity: .5;
    }

    &::before {
        top: $spacer;
        right: -20px;
        bottom: 20px;
        left: $spacer;
        animation: layers-layer-before 6s infinite;
    }

    &::after {
        top: $spacer;
        right: 20px;
        bottom: -20px;
        left: $spacer;
        animation: layers-layer-after 8s infinite;
    }

    &.top-right {
        top: $spacer;
        right: $spacer;
        left: initial;

        &::before {
            top: $spacer;
            right: $spacer;
            bottom: 20px;
            left: -20px;
            animation: layers-top-right-layer-before 6s infinite;
        }

        &::after {
            top: $spacer;
            right: $spacer;
            bottom: -20px;
            left: 20px;
            animation: layers-top-right-layer-after 8s infinite;
        }
    }

    &.bottom-right {
        top: initial;
        right: $spacer;
        bottom: $spacer;
        left: initial;

        &::before {
            top: 20px;
            right: $spacer;
            bottom: $spacer;
            left: -20px;
            animation: layers-bottom-right-layer-before 6s infinite;
        }

        &::after {
            top: -20px;
            right: $spacer;
            bottom: $spacer;
            left: 20px;
            animation: layers-bottom-right-layer-after 8s infinite;
        }
    }

    &.bottom-left {
        top: initial;
        bottom: $spacer;
        left: $spacer;

        &::before {
            top: 20px;
            right: -20px;
            bottom: $spacer;
            left: $spacer;
            animation: layers-bottom-left-layer-before 6s infinite;
        }

        &::after {
            top: -20px;
            right: 20px;
            bottom: $spacer;
            left: $spacer;
            animation: layers-bottom-left-layer-after 8s infinite;
        }
    }
}

@keyframes layers-layer-before {
    0% {
        right: -15px;
        bottom: 20px;
    }
    50% {
        right: -25px;
        bottom: 25px;
    }
    100% {
        right: -15px;
        bottom: 20px;
    }
}

@keyframes layers-layer-after {
    0% {
        right: 20px;
        bottom: -15px;
    }
    50% {
        right: 30px;
        bottom: -20px;
    }
    100% {
        right: 20px;
        bottom: -15px;
    }
}

@keyframes layers-top-right-layer-before {
    0% {
        bottom: 20px;
        left: -15px;
    }
    50% {
        bottom: 25px;
        left: -30px;
    }
    100% {
        bottom: 20px;
        left: -15px;
    }
}

@keyframes layers-top-right-layer-after {
    0% {
        bottom: -15px;
        left: 20px;
    }
    50% {
        bottom: -20px;
        left: 30px;
    }
    100% {
        bottom: -15px;
        left: 20px;
    }
}

@keyframes layers-bottom-right-layer-before {
    0% {
        top: 20px;
        left: -15px;
    }
    50% {
        top: 25px;
        left: -30px;
    }
    100% {
        top: 20px;
        left: -15px;
    }
}

@keyframes layers-bottom-right-layer-after {
    0% {
        top: -15px;
        left: 20px;
    }
    50% {
        top: -20px;
        left: 30px;
    }
    100% {
        top: -15px;
        left: 20px;
    }
}

@keyframes layers-bottom-left-layer-before {
    0% {
        top: 20px;
        right: -15px;
    }
    50% {
        top: 25px;
        right: -30px;
    }
    100% {
        top: 20px;
        right: -15px;
    }
}

@keyframes layers-bottom-left-layer-after {
    0% {
        top: -15px;
        right: 20px;
    }
    50% {
        top: -20px;
        right: 30px;
    }
    100% {
        top: -15px;
        right: 20px;
    }
}
