.indexedStep {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #e20074;
}

.test {
  position: absolute;
  margin-top: 40px;
  padding-top: 15px;
  white-space: nowrap;
}

.RSPBprogressBar {
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgba(211, 211, 211, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #e20074;
  z-index: -1;
}

.test-case-selection {
  justify-content: center;
  margin-top: 1rem;
}

.test-case-selection-splitter {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.test-case-selection-splitter > button {
  display: flex;
  justify-content: center;
}

.test-case-tree-view {
  overflow-y: scroll;
  min-height: 319px;
  height: 100%;
}

.test-case-selection-box {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  height: 400px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.test-case-details {
  background-color: #ededed;
  margin-top: 1rem;
}

.test-case-details-label {
  padding: 1rem;
}

.test-case-content {
  margin: 0 1.3rem;
  margin-bottom: 0.2rem;
  background-color: white;
  width: 100%;

  div {
    background-color: white;
    padding: 0;
    margin: 0;
  }
}

.test-case-search {
  margin-bottom: 1px;
  padding: 1px;
}

.test-case-add {
  display: flex;
}

.job-label {
  padding: 1rem;
  font-weight: bold;
}

.link-text {
  color: #0000FF;
}

.link-text:hover {
  cursor: pointer;
  color: #0000FF;
  text-decoration: none;
}

.at-show-all-button {
  background-color: white;
  margin: 0 1.3rem 0.3rem 0;
}

.icon-button-tag {
  background-color: transparent;
  padding: 0.2rem 0.2rem;

  &:hover:enabled {
    color: rgb(226, 0, 116);
  }
}
