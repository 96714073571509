.overflow-auto { overflow: auto; }
.overflow-x-auto { overflow: auto; }
.overflow-y-auto { overflow: auto; }

.overflow-hidden { overflow: hidden; }
.overflow-x-hidden { overflow: hidden; }
.overflow-y-hidden { overflow: hidden; }

.overflow-scroll { overflow: scroll; }
.overflow-x-scroll { overflow: scroll; }
.overflow-y-scroll { overflow: scroll; }
