.navbar-2-extended {
    position: relative;
    z-index: 1020;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: $spacer 0;
    background-color: $white;
    border-top: 4px solid $pink;
    border-bottom: 1px solid $border-color;

    > .container,
    > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &.sticky {
        position: sticky;
        top: 0;
    }

    .navbar-portal-name {
        margin: 0 $spacer * 4 0 0;
        font-size: 1.166rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .navbar-navigation-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-right: $grid-gutter-width * -.5;
        margin-left: $grid-gutter-width * -.5;
    }

    .navbar-portal-name + .navbar-navigation-container {
        margin-left: 0;
    }

    .navbar-navigation {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        margin: 0;
        list-style: none;

        &:first-of-type {
            .navbar-navigation-item:first-of-type {
                & > .btn {
                    margin-left: 0;
                }

                /* & > .btn-icon {
                    margin-left: ($spacer / 2) * -1;
                }

                & > .btn-profile-icon {
                    margin-left: $spacer * -1;
                } */

                // stylelint-disable-next-line selector-max-class
                & .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer * -1;
                }
            }
        }

        &:last-of-type:not(:first-of-type) {
            .navbar-navigation-item:last-of-type {
                & > .btn {
                    margin-right: 0;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: 0;
                }

                & > .btn-icon {
                    margin-right: $spacer * 0.5;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn-icon ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn-icon ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer * 0.5;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn-profile ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn-profile ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer;
                }
            }
        }
    }

    .navbar-navigation-item {
        margin: 0 $spacer * 0.5;

        &:not(.navbar-navigation-mega-dropdown-item) {
            position: relative;
        }

        & > .btn {
            padding: ($spacer * 0.5) $spacer;
            font-size: 1.166rem;
            border-radius: $spacer * 0.25;

            &.btn-link {
                @include hover-focus {
                    color: $pink;
                    background-color: initial;
                    border-color: $gray-100;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                    color: $pink;
                    background-color: initial;

                    // stylelint-disable-next-line selector-max-class
                    &:focus {
                        color: $pink;
                    }
                }

                &:not(:disabled):not(.disabled).open {
                    color: $pink;
                    background-color: $gray-100;

                    // stylelint-disable-next-line selector-max-class
                    &:focus {
                        color: $pink;
                    }
                }
            }

            &.btn-icon {
                position: relative;
                padding: ($spacer * 0.5);
                @include hover-focus {
                    color: inherit;
                }

                // stylelint-disable-next-line selector-max-class
                & > .icon {
                    font-size: $spacer * 2;
                    line-height: 1.166rem;
                }

                // stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
                & > .icon + * {
                    margin-left: $spacer;
                }
            }

            .badge {
                @extend .badge;

                position: absolute;
                top: -10px;
                right: -10px;
            }
        }
    }

    .navbar-dropdown {
        position: absolute;
        z-index: 1000;
        display: none;
        min-width: 180px;
        padding: 0;
        margin-top: ($spacer * .25);
        overflow: hidden;
        list-style: none;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;

        &.active {
            display: block;
        }

        &.navbar-dropdown-right-aligned {
            right: 0;
        }

        &.navbar-dropdown-profile {
            min-width: 240px;
            padding: $spacer * 2;
        }

        &.navbar-dropdown-condensed {
            min-width: 100%;

            .navbar-dropdown-item {
                &:not(.navbar-dropdown-button-item):not(.navbar-dropdown-link-item):not(.navbar-dropdown-subtitle-item):not(.navbar-dropdown-title-item) {
                    > * {
                        padding: $spacer ($spacer * 1.5) $spacer $spacer;
                    }
                }
            }
        }

        .navbar-dropdown-item{
            font-weight: 500;

            &:not(.navbar-dropdown-button-item):not(.navbar-dropdown-title-item):not(.navbar-dropdown-subtitle-item):not(.navbar-dropdown-link-item) {
                > * {
                    width: 100%;
                    padding: ($spacer * 1.5) ($spacer * 1.5) ($spacer * 1.5) $spacer;
                    text-align: left;
                    border-radius: 0;
                }
            }

            &.navbar-dropdown-button-item {
                margin: ($spacer * 2) 0 ($spacer * 0.5);

                .btn:not(.btn-light) {
                    @extend .btn-outline-secondary;
                }
            }

            &.navbar-dropdown-link-item {
                margin-top: $spacer * 0.25;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                color: $blue;

                & > a {
                    /* stylelint-disable-line max-nesting-depth, scss/selector-no-redundant-nesting-selector */
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        /* stylelint-disable-line max-nesting-depth */
                        text-decoration: underline;
                    }
                }

                :first-child {
                    /* stylelint-disable-line max-nesting-depth */
                    margin-top: 0;
                }
            }

            &.navbar-dropdown-title-item {
                font-weight: 600;
            }

            &.navbar-dropdown-subtitle-item {
                margin-bottom: $spacer;
                font-size: $font-size-sm;
            }
        }
    }

    .navbar-mega-dropdown-container {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        display: none;
        margin-top: 1px;
        background-color: $white;

        &.active {
            display: block;
        }

        .navbar-mega-dropdown {
            padding-top: $spacer;
        }

        .navbar-mega-dropdown-column {
            @extend .col-3;
            padding: 0;
            margin: 0;
            list-style: none;

            &:not(:last-child) {
                border-right: 1px solid $gray-100;
            }
        }

        .navbar-mega-dropdown-item {
            > * {
                width: 100%;
                padding: ($spacer * 0.5) $spacer;
                text-align: left;
                white-space: normal;
                border-radius: 0;
            }

            &.navbar-mega-dropdown-title-item {
                > * {
                    display: block;
                    margin-bottom: $spacer * 0.5;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                }
            }
        }

        .navbar-mega-dropdown-title-link {
            width: 100%;
        }

        .navbar-mega-dropdown-item + .navbar-mega-dropdown-title-item {
            margin-top: $spacer * 1.5;
        }

        .container,
        .container-fluid {
            position: relative;

            .navbar-mega-dropdown-close-button {
                @extend .btn, .btn-link, .icon, .icon-navigation-up;
                bottom: 0;
                left: 50%;
                padding: 4px 10px 0;
                font-size: 36px;
                border-radius: 0;
                transform: translate(-50%);
            }
        }
    }
}

.navbar-2-extended-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    background-color: #000;

    &.show {
        opacity: .5;
    }
}

.navbar-2-extended-open {
    overflow: hidden;
}
