/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 15em;
  height: 3em;
  line-height: 3;
  background: white;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 0;

  &-mini {
    @extend .select;
    width: 6em;
    float: right;
  } 

  /* Reset Select */
  .page-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: white;
  }

  /* Remove IE arrow */
  .page-select::-ms-expand {
    display: none;
  }
}

.page-select {
  flex: 1;
  padding: 0 .5em;
  color: #aaa;
  cursor: pointer;
}

/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #e20074;
  border: 1px solid #e20074;
  color: white;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}

/* Transition */
.select:hover::after {
  background: #bc0060;
}

.select-items {
  background: #feffff;
}

.table .filter-icon.active {
  color:  #e20074;;
}
