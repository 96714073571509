.input-group {
    .autocomplete-container,
    .datepicker-container {
        position: relative; // For focus state's z-index
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        margin-bottom: 0;

        // Bring the "active" form control to the top of surrounding elements
        &:focus {
            z-index: 3;
        }

        &:not(:last-child) {
            .autocomplete {
                @include border-right-radius(0);
            }
        }
        &:not(:first-child) {
            .autocomplete {
                @include border-left-radius(0);
            }
        }
    }
}

.input-group > .input-group-prepend .autocomplete,
.input-group > .input-group-prepend > .btn-group > .btn:last-child {
    @include border-right-radius(0);
}

.input-group > .input-group-append .autocomplete,
.input-group > .input-group-append > .btn-group > .btn:first-child {
    @include border-left-radius(0);
}

.input-group-lg .autocomplete {
    @extend .autocomplete-lg;
}

.input-group-sm .autocomplete {
    @extend .autocomplete-sm;
}
