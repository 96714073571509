.plain-link {
    color: inherit;
    text-decoration: none;
    outline-offset: 5px;
    transition: all 150ms cubic-bezier(.445, .05, .55, .95);

    @include hover-focus {
        color: inherit;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 5px auto $btn-focus-color;
        outline-offset: 3px;
    }
}
