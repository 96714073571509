@use "sass:math";

.autocomplete {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: $custom-select-height;
    line-height: $custom-select-line-height;
    color: $custom-select-color;
    vertical-align: middle;
    cursor: default;
    background-size: $custom-select-bg-size;
    border: $custom-select-border-width solid $custom-select-border-color;
    outline: 0 !important; // stylelint-disable-line declaration-no-important
    transition: all 100ms;

    transition: all 150ms cubic-bezier(.445, .05, .55, .95);
    appearance: none;

    @if $enable-rounded {
        border-radius: $custom-select-border-radius;
    } @else {
        border-radius: 0;
    }

    &:hover {
        background-color: $gray-100;

        .autocomplete-multi-value {
            background-color: $white;
        }
    }

    &:active {
        background-color: $gray-200;
    }

    &.focus {
        border-color: $custom-select-focus-border-color;
        outline: 0;
        box-shadow: $custom-select-focus-box-shadow;

        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // suppress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
            color: $input-color;
            background-color: $input-bg;
        }

        &:hover {
            background-color: $white;

            &:active {
                background-color: $gray-200;

                .autocomplete-multi-value {
                    background-color: $white;
                }
            }

            .autocomplete-multi-value {
                background-color: $secondary;
            }
        }
    }

    &.disabled {
        color: $custom-select-disabled-color;
        background-color: $custom-select-disabled-bg;
    }

    &.readonly {
        pointer-events: none;
        background-color: $input-readonly-bg;
        border-color: $input-readonly-bg;
    }

    &.plaintext {
        padding-top: $input-padding-y;
        padding-bottom: $input-padding-y;
        margin-bottom: 0; // match inputs if this class comes on inputs with default margins
        line-height: $input-line-height;
        color: $input-plaintext-color;
        background-color: transparent;
        border: solid transparent;
        border-width: $input-border-width 0;

        &.autocomplete-sm,
        &.autocomplete-lg {
            padding-right: 0;
            padding-left: 0;
        }
    }

    // Hides the default caret in IE11
    &::-ms-expand {
        opacity: 0;
    }
}

.autocomplete-container {
    position: relative;
    box-sizing: border-box;
}

.autocomplete-indicator {
    margin-right: $spacer;
}

.autocomplete-multi-value {
    box-sizing: border-box;
    display: flex;
    min-width: 0;
    margin: 1px;
    background-color: $secondary;
    border-radius: 2px;

    &.disabled {
        color: $custom-select-disabled-color;
        background-color: $custom-select-disabled-bg;
    }
}

.autocomplete-multi-value-label {
    padding: 6px;
    padding-left: 6px;
    overflow: hidden;
    font-size: 83%;
    line-height: 1.21;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: $border-radius;
}

.autocomplete-multi-value-remove {
    display: flex;
    align-items: center;
    padding: ($spacer * 0.25) ($spacer * 0.5) 0 ($spacer * 0.5);
    font-size: 14px;
    color: $gray-600;
    border-radius: 0 $border-radius $border-radius 0;

    &:hover {
        background-color: lighten($red, 35%);
    }
}

.autocomplete-header {
    @extend .dropdown-header;
}

.autocomplete-menu {
    @extend .dropdown-menu;
    z-index: $zindex-dropdown;
    min-width: 100%;
    max-height: 35vh;
    margin-top: 10px;
    overflow-y: auto;
}

.autocomplete-item {
    @extend .dropdown-item;

    &.focus {
        background-color: $secondary;
    }
}

.autocomplete-placeholder {
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateY(-50%);
}

.autocomplete-placeholder-multi {
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    max-width: calc(100% - 8px);
    padding-right: ($input-padding-x - math.div($spacer, 6));
    padding-left: ($input-padding-x - math.div($spacer, 6));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateY(-50%);
}

.autocomplete-value-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;

    padding: 0 $input-padding-x;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    &.plaintext {
        padding: 0;
    }
}

.autocomplete-value-container-multi {
    @extend .autocomplete-value-container;
    padding: 1px;
}

.autocomplete-sm {
    min-height: $custom-select-height-sm;
    padding-top: $custom-select-padding-y;
    padding-bottom: $custom-select-padding-y;
    font-size: $custom-select-font-size-sm;
}

.autocomplete-lg {
    min-height: $custom-select-height-lg;
    padding-top: $custom-select-padding-y;
    padding-bottom: $custom-select-padding-y;
    font-size: $custom-select-font-size-lg;
}

@mixin autocomplete-validation-state($state, $color) {
    .autocomplete {
        .was-validated &:#{$state},
        &.is-#{$state} {
            background-color: rgba($color, .07);
            border-color: $color;

            &:hover {
                background-color: rgba($color, .15);
                border-color: darken($color, 10%);
            }

            &:focus {
                background-color: $white;
                border-color: $color;
                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }
}

@include autocomplete-validation-state("valid", $form-feedback-valid-color);
@include autocomplete-validation-state("invalid", $form-feedback-invalid-color);
