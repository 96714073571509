.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  margin-top: auto;
  margin-bottom: auto;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.spinner {
  margin: 20px auto 20px auto;
}
