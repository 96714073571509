.brandbar {
    position: relative;
    z-index: 1020;
    padding-top: $spacer *2;
    padding-bottom: $spacer *2;
    background-color: $pink;

    > .container,
    > .container-fluid {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.brandbar-claim {
    font-size: .8rem;
    font-weight: $font-weight-bold;
    line-height: 1rem;
    color: $white;
    text-transform: uppercase;
}
