.navbar-extended {
    position: relative;
    z-index: 1020;
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
    background-color: $white;
    border-top: 4px solid $pink;
    border-bottom: 1px solid $border-color;

    > .container,
    > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &.sticky {
        position: sticky;
        top: 0;
    }

    .navbar-portal-name {
        margin: 0 $spacer * 4 0 0;
        font-size: 1.166rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .navbar-navigation-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-right: $grid-gutter-width  * -.5;
        margin-left: $grid-gutter-width * -.5;
    }

    .navbar-portal-name + .navbar-navigation-container {
        margin-left: 0;
    }

    .navbar-navigation {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        margin: 0;
        list-style: none;

        &:first-of-type {
            .navbar-navigation-item:first-of-type {
                & > .btn {
                    margin-left: 0;
                }

                & > .btn-icon {
                    margin-left: $spacer * 0.5;
                }

                // stylelint-disable-next-line selector-max-class
                & .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer * -1;
                }
            }
        }

        &:last-of-type:not(:first-of-type) {
            .navbar-navigation-item:last-of-type {
                & > .btn {
                    margin-right: 0;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: 0;
                }

                & > .btn-icon {
                    margin-right: $spacer * 0.5;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn-icon ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn-icon ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer * 0.5;
                }

                // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors, selector-max-combinators
                & > .btn-profile ~ .navbar-dropdown.navbar-dropdown-right-aligned,
                & > .btn-profile ~ * .navbar-dropdown.navbar-dropdown-right-aligned {
                    right: $spacer;
                }
            }
        }
    }

    .navbar-navigation-item {
        &:not(.navbar-navigation-mega-dropdown-item) {
            position: relative;
        }

        & > .btn {
            padding: ($spacer * 0.5) $spacer;
            margin-right: $spacer * 3;

            font-size: 1.166rem;

            .badge {
                position: absolute;
                top: -10px;
                right: -10px;
            }
        }

        & > .btn-icon {
            position: relative;
            padding: ($spacer * 0.5);
            margin-right: $spacer;

            & > .icon {
                font-size: $spacer * 2;
                line-height: 1.166rem;
            }

            // stylelint-disable-next-line selector-max-compound-selectors
            & > .icon + * {
                margin-left: $spacer;
            }

            // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors
            & ~ .navbar-dropdown.navbar-dropdown-right-aligned,
            & ~ div .navbar-dropdown.navbar-dropdown-right-aligned {
                right: $spacer;
            }
        }
    }

    .navbar-dropdown {
        position: absolute;
        z-index: 1000;
        display: none;
        min-width: 180px;
        padding: 0;
        margin: $spacer 0 0 0;
        overflow: hidden;
        list-style: none;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;

        &.active {
            display: block;
        }

        &.navbar-dropdown-right-aligned {
            right: $spacer * 3;
        }

        &.navbar-dropdown-profile {
            min-width: 240px;
            padding: $spacer * 2;
        }

        &.navbar-dropdown-condensed {
            min-width: calc(100% - #{$spacer * 3});

            .navbar-dropdown-item {
                &:not(.navbar-dropdown-button-item):not(.navbar-dropdown-link-item):not(.navbar-dropdown-subtitle-item):not(.navbar-dropdown-title-item) {
                    > * {
                        padding: $spacer ($spacer * 1.5) $spacer $spacer;
                    }
                }
            }
        }

        .navbar-dropdown-item {
            font-weight: 500;

            &:not(.navbar-dropdown-button-item):not(.navbar-dropdown-link-item):not(.navbar-dropdown-subtitle-item):not(.navbar-dropdown-title-item) {
                > * {
                    width: 100%;
                    padding: ($spacer * 1.5) ($spacer * 1.5) ($spacer * 1.5) $spacer;
                    text-align: left;
                    border-radius: 0;
                }
            }

            &.navbar-dropdown-button-item {
                margin: ($spacer * 2) 0 ($spacer * 0.5);

                .btn:not(.btn-light) {
                    @extend .btn-outline-secondary;
                }
            }

            &.navbar-dropdown-link-item {
                margin-top: $spacer * 0.25;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;
                color: $blue;

                & > a {
                    /* stylelint-disable-line max-nesting-depth, scss/selector-no-redundant-nesting-selector */
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        /* stylelint-disable-line max-nesting-depth */
                        text-decoration: underline;
                    }
                }

                :first-child {
                    /* stylelint-disable-line max-nesting-depth */
                    margin-top: 0;
                }
            }

            &.navbar-dropdown-title-item {
                font-weight: 600;
            }

            &.navbar-dropdown-subtitle-item {
                margin-bottom: $spacer;
                font-size: $font-size-sm;
            }
        }
    }

    .navbar-mega-dropdown-container {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        display: none;
        margin-top: 1px;
        background-color: $white;

        &.active {
            display: block;
            border-bottom: 1px solid $border-color;
        }

        .navbar-mega-dropdown {
            padding: ($spacer * 4.5) 0 ($spacer * 2.5);
        }

        .navbar-mega-dropdown-column {
            @extend .col-3;
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .navbar-mega-dropdown-item {
            > * {
                display: block;
                width: 100%;
                padding: ($spacer * 1.5) ($grid-gutter-width * 0.5);
                text-align: left;
                white-space: normal;
                border-radius: 0;
            }

            &.navbar-mega-dropdown-title-item {
                > * {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .container,
        .container-fluid {
            position: relative;

            .navbar-mega-dropdown-close-button {
                @extend .btn, .btn-link, .icon, .icon-cancel;
                position: absolute;
                top: $spacer * -2.5;
                right: ($spacer * 0.5) * -1;
                width: 36px;
                height: 36px;
                padding: 0;
            }
        }
    }
}
