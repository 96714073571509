// stylelint-disable declaration-no-important

.h-0 { height: 0 !important; }
.w-0 { width: 0 !important; }

.mh-25 { max-height: 25% !important; }
.mh-50 { max-height: 50% !important; }
.mh-75 { max-height: 75% !important; }

.mw-25 { max-width: 25% !important; }
.mw-50 { max-width: 50% !important; }
.mw-75 { max-width: 75% !important; }

.vh-25 { height: 25vh !important; }
.vh-50 { height: 50vh !important; }
.vh-75 { height: 75vh !important; }
.vh-100 { height: 100vh !important; }

.vw-25 { width: 25vw !important; }
.vw-50 { width: 50vw !important; }
.vw-75 { width: 75vw !important; }
.vw-100 { width: 100vw !important; }

.mvh-25 { max-height: 25vh !important; }
.mvh-50 { max-height: 50vh !important; }
.mvh-75 { max-height: 75vh !important; }
.mvh-100 { max-height: 100vh !important; }

.mvw-25 { max-width: 25vw !important; }
.mvw-50 { max-width: 50vw !important; }
.mvw-75 { max-width: 75vw !important; }
.mvw-100 { max-width: 100vw !important; }
