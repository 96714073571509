// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}


@mixin form-validation-state($state, $color) {
  .#{$state}-feedback {
    display: none;
    //width: 100%;
    margin-top: $form-feedback-margin-top;
    padding: (($spacer*0.5)-.075rem) $spacer*0.5;
    font-size: $form-feedback-font-size;
    color: $gray-500;

    background-color: rgba($color, .1);
    -webkit-border-radius:$border-radius;
    -moz-border-radius:$border-radius;
    border-radius: $border-radius;
  }

  .#{$state}-tooltip {
    display: none;
    //width: 100%;
    padding: .5rem;
    margin-top: .25rem;
    font-size: .875rem;
    line-height: 1;
    color: $gray-500;
    background-color: rgba($color, .1);
    border-radius: .2rem;
  }

  input.form-control:enabled.is-#{$state}:hover,
  textarea.form-control:enabled.is-#{$state}:hover {
    background-color: rgba($color, .15);
    border-color: darken($color, 10%);

    &:focus {
      &:not(:active) {
        background-color: $white;
      }
    }
  }

    .input-group .form-control,
    .input-group .custom-select {
      .was-validated &:#{$state},
      &.is-#{$state} {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
          width: 100%;
        }
      }
     }

  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;
      background-color: rgba($color, .07);

      &:hover {
          background-color: rgba($color, .15);
          border-color: darken($color, 10%);
      }

      &:focus {
        border-color: $color;
        background-color: $white;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .form-control-file {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .form-check-label {
        color: $gray-500;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: inline-block;
      }

      ~ .#{$state}-feedback {
        margin-left: -1.25rem;
      }
    }
  }

  .custom-control-input {
    .was-validated &:invalid,
    &.is-invalid {
      ~ .custom-control-label {
        color: $gray-500;

        &::before {
          border-color: $color;
          background-color: #ebddde;
        }

        &:hover {
          &::before {
            border-color: darken($color, 10%);
            background-color: #ebcbcd !important;
          }

          &:active {
            &::before {
              border-color: darken($color, 20%);
              background-color: $gray-300 !important;
            }
          }
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: table;
        margin-bottom: 15px;
      }

      ~ .#{$state}-feedback {
        margin-left: -2.2rem;
      }

      &:checked {
        ~ .custom-control-label::before {
          //@include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }

  // custom file
  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-file-label {
        border-color: $color;

        &::before { border-color: inherit; }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:focus {
        ~ .custom-file-label {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }
}
