.step_input{
    float: left;
    width: 60px;
    margin: 2px;
}

.step{
    margin: 2px;
    width: 100%;
}

// only first svg - drag&drop
.step > svg {
    float: left;
    clear: both;
    margin: 6px;
}

.step_row {
    float: left;
    height: 29px;
    text-align: center;
    line-height: 29px;
    margin-left: 2px;
    margin-right: 2px;
}

.available_step_row {
    border-bottom: 2px solid #e20074;
    margin-right: 0;
    padding-right: 2px;
}

.step_object_row {
    border-style: solid;
    margin: 2px;
    border-color: gray;
    border-width: 2px;
}

.selected_step_object_row {
    border-style: solid;
    border-color: #e20074;
    border-width: 2px;
    margin: 2px;
}

.unfilled_step_variable {
    background: #757575;
}

.warn {
    background-color: red;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
}

.small_button {
    height: 20px;
    width: 20px;
    padding: 0;
    margin: 4px;
}

.available_step_col {
    border: 0 solid grey;
    border-right-width: 5px;
}

.available_step_add_button {
    border-bottom: 2px solid #e20074;
    margin-top: 9px;
    margin-left: 0;
}

.small_button svg {
    padding-bottom: 5px;
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.viewport_height {
    height: calc(100vh - 55px);
}

.step_editor_height {
    // viewport - header - footer
    height: calc(100vh - 55px - 140px);
    align-content: end;
}

.tags span {
    padding: 3px;
    margin: 2px;
    background-color: #373A3C;
    height: 22px;
    vertical-align: top;
}

.tags span button {
    margin: 0 0 0 2px;
    background-color: lightgrey;
    border: none;
    width: 15px;
    height: 15px;
    padding: 0;
}

.tags input {
    min-width: 22px;
    height: 22px;
    padding: 0 5px 0 6px;
    background-color: #373A3C;
    border: none;
    color: white;
    outline-color: white;
    vertical-align: top;
    margin: 2px;
}

.tags input::placeholder {
    color: lightgrey;
}

button {
    margin: 2px;
}

.export-button {
    width: 85px;
    margin-top: 15px;
}

.feature-file {
    background-color: #eeeeee;
    margin: 5px;
    padding: 5px;
}

.feature-file-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feature-file-buttons {
    Button {
        padding: 0;
        margin: 0 5px;
    }
}

.ff-collapsed {
    overflow: hidden;
    padding: 0;
    margin: 0;
    height: 0;
}

.add-scenario-button {
    text-align: center;
}

.action-buttons-col {
    text-align: right;
}

.scenario {
    background-color: white;
    padding: 5px;
}

.scenario.collapsed {
    overflow: hidden;
    padding: 0 5px;
    margin-bottom: 0;
    height: 0;
}

.scenario-buttons {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    
    Button {
        padding: 0;
        margin: 0 5px;
    }
}

.name-input {
    width: 20rem;
}

.align_right {
    float: right;
}

.align_left {
    float: left;
}

.available-step {
    margin-left: 15px;
}

.available-step > div {
    background-color: #F5F5F5;
}

.react-dnd-step-list-transform ~ div{
    transform: none!important;
}

.react-beatiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
    display: none !important;
}

.hidden {
    display: none;
}

.step-container-empty {
    border: 1px dashed black;
}

.droppable-step-list {
    display: inline-block;
}

.step-area-empty {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.5rem 0.5rem;
    line-height: 1.5;
    color: #aaa;
}