@mixin caret-down {
    margin: 0 0 -3px 10px;
}

@mixin caret-up {
    transform: rotate(180deg);
    margin: 0 0 2px 10px;
}

@mixin caret-right {
    transform: rotate(-90deg);
    margin-bottom: 0;
}

@mixin caret-left {
    transform: rotate(90deg);
    margin-bottom: 0;
}

@mixin caret($direction: down) {
    @if $enable-caret {
        &::after {
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            line-height: 1;
            background-image: $arrow-icon;
            background-repeat: no-repeat;

            @if $direction == down {
                @include caret-down;
            } @else if $direction == up {
                @include caret-up;
            } @else if $direction == right {
                @include caret-right;
            }@else if $direction == left {
                @include caret-left;
            }
        }
    }
}
