.form-control {
    &:disabled {
        color: $custom-select-disabled-color;
        background-color: $custom-select-disabled-bg;
    }

    &[readonly] {
        pointer-events: none;
        background-color: $input-readonly-bg;
        border-color: $input-readonly-bg;
    }
}

.form-control-plaintext {
    &[readonly] {
        pointer-events: none;
    }
}

@mixin validation-state($state, $color) {
    .is-#{$state} {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }
}

@include validation-state("valid", $form-feedback-valid-color);
@include validation-state("invalid", $form-feedback-invalid-color);
