// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    padding: 0.33rem 1.5rem 0.421rem;

    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-image: none;
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

// stylelint-disable

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value);
    }
}

.btn-primary {
    @include button-variant($pink, #b2005c, darken($pink, 3%), darken(#b2005c, 3%), darken($pink, 6%), darken(#b2005c, 6%));
}

.btn-secondary {
    @include button-variant($gray-100, $gray-300, darken($gray-100, 7%), darken($gray-300, 5%), darken($gray-100, 14%), darken($gray-300, 11%));
}

.btn-light {
    @include button-variant($white, $gray-300);
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// stylelint-enable

//
// Ergänzungen für Telekom
//

.btn- {
    &secondary,
    &success {
        color: $gray-500;

        &:not(:disabled):not(.disabled):hover {
            color: $gray-500;
        }
    }

    &primary,
    &danger,
    &info,
    &dark {
        color: $white;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            color: $white;
        }
    }
}

.btn-outline- {
    &secondary,
    &light {
        color: $gray-500;
    }

    &secondary {
        border-color: $gray-300;
    }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    margin: 0;

    @include button-variant(transparent, transparent, $gray-100, transparent, $gray-300, transparent);
    font-weight: $font-weight-normal;
    color: $gray-500;
    background-color: transparent;

    @include hover {
        color: $gray-500;
        text-decoration: none;
    }

    &:active,
    &.active {
        background-color: $gray-200;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        text-decoration: none;
        border-color: transparent;
        outline: 5px auto $input-btn-focus-color;
        outline-offset: -2px;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $gray-500;
        pointer-events: none;
        border-color: transparent;
    }

    // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    padding: .563rem 1.5rem .75rem;
    line-height: 1.2;
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    padding: .4rem 1.5rem .476rem;
    line-height: 1;
}

//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
