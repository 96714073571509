// stylelint-disable declaration-no-important

// Building unit margin and padding

$bu-spacers: (
    0: 0,
    "0_5": ($spacer * .5),
    1: ($spacer * 1),
    "1_5": ($spacer * 1.5),
    2: ($spacer * 2),
    "2_5": ($spacer * 2.5),
    3: ($spacer * 3),
    "3_5": ($spacer * 3.5),
    4: ($spacer * 4),
    "4_5": ($spacer * 4.5),
    5: ($spacer * 5)
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $bu-spacers {

                .bu-#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
                .bu-#{$abbrev}t#{$infix}-#{$size},
                .bu-#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }
                .bu-#{$abbrev}r#{$infix}-#{$size},
                .bu-#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
                .bu-#{$abbrev}b#{$infix}-#{$size},
                .bu-#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }
                .bu-#{$abbrev}l#{$infix}-#{$size},
                .bu-#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // Some special margin utils
        .bu-m#{$infix}-auto { margin: auto !important; }
        .bu-mt#{$infix}-auto,
        .bu-my#{$infix}-auto {
            margin-top: auto !important;
        }
        .bu-mr#{$infix}-auto,
        .bu-mx#{$infix}-auto {
            margin-right: auto !important;
        }
        .bu-mb#{$infix}-auto,
        .bu-my#{$infix}-auto {
            margin-bottom: auto !important;
        }
        .bu-ml#{$infix}-auto,
        .bu-mx#{$infix}-auto {
            margin-left: auto !important;
        }
    }
}
