@mixin alert-variant($background, $border, $color) {
  color: $gray-500;
  background-color: rgba($background, .05);
  border-color: $border;

  hr {
    border-top-color: $gray-500;
  }

  .alert-link {
    color: $gray-500;
  }
}

