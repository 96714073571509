.table-responsive {
    .table {
        thead {
            .table-sticky-header {
                th {
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                    z-index: 2;
                }

                th.table-sticky-th-left {
                    z-index: 3;
                }
            }
        }

        .table-sticky-td-right,
        .table-sticky-th-right {
            position: sticky;
            right: 0;
            background-color: #fff;
            z-index: 1;
        }

        .table-sticky-td-left,
        .table-sticky-th-left {
            position: sticky;
            left: 0;
            background-color: #fff;
            z-index: 1;
        }
    }

    .table-striped {
        tbody tr:nth-of-type(#{$table-striped-order}) td.table-sticky-td {
          background-color: $table-accent-bg;
        }
    } 
    
    .table-hover {
        tbody tr:hover td.table-sticky-td {
            background-color: $table-hover-bg;
        }
      }
}

.table {
    .table-sort-asc,
    .table-sort-desc {
        @extend .icon;
        margin: 0 $spacer * 0.5;
        color: $gray-300;
    }

    .table-sort-asc {
        @extend .icon-navigation-up;
    }

    .table-sort-desc {
        @extend .icon-navigation-down;
    }
}


