.close {
    outline-offset: 0;
    transition: all 150ms cubic-bezier(.445, .05, .55, .95);

    &:focus,
    &.focus {
        outline: 5px auto $btn-focus-color;
        outline-offset: -2px;
    }
}
