.hero {
    position: relative;
    display: flex;
    align-items: center;
    height: 600px;

    .hero-content {
        z-index: 1;
        flex: 1;
        color: $white;
        text-align: center;
    }

    .hero-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-position: 50%;
        background-size: cover;
    }

    &.dimmed {
        &::before {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            background: rgba(38, 38, 38, .7);
        }
    }
}
