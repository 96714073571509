$custom-datepicker-indicator: url("data:image/svg+xml;utf8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2728%27%20height%3D%2732%27%20viewBox%3D%270%200%2028%2032%27%3E%3Ctitle%3Ecalendar%3C%2Ftitle%3E%3Cpath%20fill%3D%27%23495057%27%20d%3D%27M3.563%201.563h21.313q0.75%200%201.266%200.516t0.516%201.266v24.875q0%200.75-0.516%201.266t-1.266%200.516h-21.313q-0.75%200-1.266-0.516t-0.516-1.266v-24.875q0-0.75%200.516-1.266t1.266-0.516zM3.563%2028.219h21.313v-19.563h-21.313v19.563zM3.563%203.344v3.531h21.313v-3.531h-21.313zM13.25%2025.688h-7.906q0-2.344%200.969-3.906%200.406-0.625%201.594-1.688%200.156-0.094%200.563-0.391t1.313-0.922%201.469-1.531%200.563-1.688q0-1.281-0.469-1.875-0.813-1.063-1.938-1.063-1.344%200-1.906%201.156-0.313%200.563-0.313%201.656h-1.844q0-2.969%202.25-3.938%200.875-0.375%201.844-0.375%201.906%200%203.094%201.313%201.063%201.188%201.063%202.844%200%202.5-2.094%204.031-0.594%200.438-1.25%200.875-1.688%201.156-2.016%201.5t-0.578%200.688q-0.594%200.75-0.594%201.844h6.188v1.469zM16.656%2017.875q1.063-1.563%202.781-1.563%201.688%200%202.75%201.375%200.906%201.219%200.906%203.219%200%202.156-1.094%203.438-1.031%201.344-2.781%201.344-2.188%200-3.281-2-0.625-1.156-0.734-2.438t-0.109-2.625q0-2.906%200.656-4.5t1.547-2.297%201.953-0.703q1.906%200%202.781%201.25%200.688%201%200.75%202.219h-1.594q-0.313-2.031-1.844-2.031-2%200-2.5%203.375-0.125%200.844-0.188%201.938zM19.188%2017.719q-1.125%200-1.781%201.156-0.469%200.813-0.469%201.813%200%202.563%201.25%203.313%200.469%200.281%201%200.281%201.125%200%201.719-1.031%200.531-0.938%200.531-1.875%200-2.625-1.25-3.375-0.438-0.281-1-0.281z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E") !default;

.form-control{
    &.custom-datepicker {
        background: $custom-select-bg $custom-datepicker-indicator no-repeat right $custom-select-padding-x center;
        background-size: 16px;
    }
}

.datepicker {
    @extend .dropdown-menu;
    z-index: $zindex-dropdown;
    width: 260px;
    padding: $spacer * 0.5;
    margin: 10px 0 0 -5px;
}

.datepicker-container {
    position: relative;
    box-sizing: border-box;
}

.datepicker-button {
    display: inline-flex;
    justify-content: center;
    padding: $spacer * 0.25;
    margin: 0;
    cursor: pointer;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    border: none;
    border-radius: $border-radius;
    outline-offset: 2px;
    transition: all 150ms cubic-bezier(.445, .05, .55, .95);
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

    @include hover {
        background-color: $secondary;
    }

    &.active {
        background-color: $secondary;
    }

    &.current {
        color: $white;
        background-color: $pink;
    }

    &:focus {
        outline: 5px auto $btn-focus-color;
        outline-offset: 0;
    }

    &.next {
        margin-right: 6px;
    }

    &.prev {
        margin-left: 6px;
    }
}

.datepicker-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: $spacer * 0.5;
    margin-bottom: $spacer * 0.5;
    border-bottom: 1px solid $border-color;
}

.datepicker-time {
    display: flex;
    justify-content: center;
    padding-top: $spacer * 0.5;
    margin-top: $spacer * 0.5;
    border-top: 1px solid $border-color;
}

.datepicker-date {
    .datepicker-button {
        &:not(:last-child) {
            @include border-right-radius(0);
        }
        &:not(:first-child) {
            @include border-left-radius(0);
        }
    }
}

.datepicker-hour,
.datepicker-minute,
.datepicker-second,
.datepicker-day,
.datepicker-month,
.datepicker-year {
    display: inline-flex;
    justify-content: center;
}

.datepicker-day {
    flex-basis: calc(100% / 7);
    font-size: 1rem;
}

.datepicker-month,
.datepicker-year {
    flex-basis: calc(100% / 4);
}

.datepicker-hour {
    flex-basis: calc(100% / 6);
}

.datepicker-minute,
.datepicker-second {
    flex-basis: calc(100% / 10);
}

.day:hover {
    background-color: #ededed;
}

.datepicker-hours,
.datepicker-minutes,
.datepicker-seconds,
.datepicker-days,
.datepicker-months,
.datepicker-week,
.datepicker-years {
    display: flex;
    flex-wrap: wrap;
}

.datepicker-week {
    padding: 6px 0;
    font-size: 1rem;
}

@mixin datepicker-validation-state($state, $color) {
    .custom-datepicker {
        .was-validated &:#{$state},
        &.is-#{$state} {
            background-color: rgba($color, .07);
            border-color: $color;

            &:hover {
                background-color: rgba($color, .15);
                border-color: darken($color, 10%);
            }

            &:focus {
                background-color: $white;
                border-color: $color;
                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }
}

@include datepicker-validation-state("valid", $form-feedback-valid-color);
@include datepicker-validation-state("invalid", $form-feedback-invalid-color);
